@import '/src/styles';

.anchor {
    cursor: pointer;
    border-radius: 5px;
    box-sizing: border-box;
    transition: all 0.2s;

    &__btn {
        text-align: center;
        padding: 10px 30px;

        &:hover {
            text-decoration: none;
        }

        &--default {
            color: #f8710e;
            background-color: #fbf4f4;
            border: 1px solid #f98733;

            &:hover {
                color: #f8710e;
            }
        }

        &--error {
            background: #fff;
            color: #cc6f6f;
            border: 1px solid #cc6f6f;

            &:hover {
                background: rgb(255, 240, 240);
                color: #cc6f6f;
            }
        }

        &--partial {
            background: #fff;
            color: #cc6f6f;
            border: 1px solid #cc6f6f;

            &:hover {
                background: rgb(255, 240, 240);
                color: #cc6f6f;
            }
        }

        &--cancel {
            color: #ffffff;
            border: 1px solid #ffffff;
            background: #bd3c3c;
            border: 1px solid #ba550b;

            &:hover {
                color: #ffffff;
                background: #972828;
            }
        }

        &--close {
            color: #ffffff;
            border: 1px solid #ffffff;
            background: #bd3c3c;
            border: 1px solid #ba550b;

            &:hover {
                color: #ffffff;
                background: #972828;
            }
        }

        &--link {
            padding: 4px 8px;
            font-weight: 500;
            border: none;
            color: rgba(getColor('link'), 0.7);
            background-color: transparent;
            width: auto;

            &:hover {
                color: getColor('link');
            }
        }

        &--primary {
            background: getColor('primary');
            color: #fff;
            border: 1px solid getColor('primary');

            &:hover {
                color: #fff;
                background: getColor('primary', 'dark');
            }
        }
    }
}
