@import '../../../../../../styles';

.container {
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
    flex-grow: 1;
}

.tagWrapper {
    display: flex;
    column-gap: .5rem;
    height: 25px;
}

.warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: #717785;
    padding: 20px;
    border-radius: 8px;
    flex-grow: 1;

    h2 {
        font-size: 2em;
        color: #bd3c3c;
    }

    span{
        line-height: 18px;
        font-size: 0.9em;
        color: #717785;
        color: #bd3c3c;
    }

    .icon {
        font-size: 150px!important;
        color: #bd3c3c!important    ;
    }
}

.subTitle {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: bold;
    color: #717785;

    .type {
        position: relative;
        top: -2px;
        color: #f8710e;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: -1px;
        padding-right: 5px;
    }
}
