@import '../../styles';

.pageHeader {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    min-height: 110px;
    padding: 30px;
    box-sizing: border-box;

    h2 {
      padding-right: 20px;
    }
}

.headerTitle {
    font-weight: 300;
    padding-right: 15px;
    margin: unset;

    .marker {
        color: getColor("primary", "base");
        font-weight: bold;
    }
}

.presentText {
    display: flex;
    justify-content: flex-start;
    column-gap: 40px;
    width: 100%;
    padding: 0 30px 30px 30px;
    box-sizing: border-box;

    .iconDash {
        font-size: 50px;
        margin-right: 20px;
    }
}
