@import '../../../styles';

.autoCompleteContainer {
    position: relative;
}

.inputWrapper {
    display: flex;
    padding: 0.5em 1em 0.5em 1em;
    background-color: white;
    border-radius: 5px;
    border: 1px solid getColor('secondary', 'light');
    transition: border-color 200ms ease;
    height: inherit;
    @include outline;
    &.focused {
        @include focusOutline;
        border-color: getColor('primary', 'light');
    }
    &.disabled {
        background-color: getColor('disabled', 'light');
        color: getColor('disabled');
    }
    &.invalid {
        background-color: getColor('auxRed', 'lighter');
        border: 1px solid getColor('auxRed');
        color: getColor('auxRed');
        input {
            background-color: getColor('auxRed', 'lighter');
            color: getColor('auxRed');
            &::placeholder {
                color: rgba(getColor('auxRed'), .8);
            }
        }
    }
}

.autoCompleteLabel {
    display: flex;
    flex: 1;
    position: relative;
    input {
        appearance: none;
        border: 0;
        outline: none;
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        &:disabled {
            background-color: getColor('disabled', 'light');
            color: getColor('disabled');
            &::placeholder {
                background-color: getColor('disabled', 'light');
                color: getColor('disabled');
            }
        }
    }
    .preventDiv {
        position: absolute;
        cursor: default;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &.autoCompleteField {
            cursor: text;
        }
    }
}

.autoCompleteIcon {
    transition: transform ease-out 300ms;
    transform: rotate(90deg);
    &.iconFocused {
        transform: rotate(-90deg);
    }
}

.autoCompleteIconWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 32px;
    cursor: default;
}

.inputAsSelectField {
    caret-color: transparent;
}

.errorMessage {
    color: getColor('auxRed');
}
