@import '../../styles';

.btnCont {
    width: 270px;
    min-height: 20px;
    float: left;
    z-index: 1;
}

.newIntegrationBtn {
    position: relative;
    width: 270px;
    min-height: 20px;
    border-radius: 5px;
    background: yellow;
    border-color: #7e7e7e;
    color: getColor('primary');
    background-color: #fbf4f4;
    border: 1px solid #f98733;
    cursor: pointer;
    font-weight: bold;
    padding: 10px 30px;
    box-sizing: border-box;
    text-align: center;
    float: left;
    z-index: 2;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease-in;

    span {
        position: relative;
        top: -2px;
        left: -5px;
    }

    span::before {
        background-color: #fff;
    }
}

.newIntegrationBtn:hover {
    background-color: rgb(248, 248, 248);
}

.integrationList {
    position: absolute;
    top: 68px;
    right: 30px;
    width: 270px;
    min-height: 0px;
    float: left;
    z-index: 1;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    background: #fff;

    .itemList {
        width: 100%;
        min-height: 20px;
        padding: 7px 20px;
        box-sizing: border-box;
        float: left;
        cursor: pointer;
        color: getColor('secondary');
        background: #fff;
        font-weight: 400;
        border-bottom: 1px solid #e3e3e3;
        font-size: 14px;
    }

    .itemList:hover {
        background: getColor('primary');
        color: #fff;
    }

    .itemList:last-of-type {
        border-bottom: unset;
    }

    .itemList:first-of-type {
        position: relative;
        padding-top: 15px;
    }

    .disabledItem,
    .disabledItem:hover {
        /* background: #EEE; */
        color: #c9c9c9;
        cursor: unset;
        background: #fff;
        border-bottom: 1px solid #ddd;
    }

    .disabledItem:hover {
        background: #ddd;
        color: #fff;
    }
}
