@import '../../../../styles';

.wrapperDialog {
    width: 700px;
}

.modal {
    width: 900px !important;
    max-height: calc(100% - 60px) !important;
    min-height: unset !important;
    padding-bottom: 0 !important;
    background: #f9f9f9 !important;
    filter: blur(-0.000001px);

    &.displayMaxContent {
        height: 100%;
    }
}

.modal .cont {
    height: calc(100% - 60px) !important;
}

.tab {
    width: 100%;
    height: calc(100% - 215px);
    overflow-y: auto;
    padding: 10px 15px 20px 15px;
    box-sizing: border-box;
    border: 1px solid getColor('secondary', 'light');
    background: #fff;
    border-radius: 0 5px 5px 5px;
}

.halfInput {
    width: calc(61% - 5px);
}

.halfInput:first-child {
    width: 30%;
    margin-right: 10px;
}

.inputSpaceLeft {
    margin-left: 0.5rem !important;
}

.inputSpaceRight {
    margin-right: 0.5rem !important;
}

.fieldIntegration {
    width: 70%;
    height: 36px;
    border-radius: 5px;
    padding: 5px 8px;
    border: 1px solid #c4c4c4;
    &:invalid {
        border: 1px solid getColor('auxRed');
        color: getColor('auxRed');
    }
    &.tactic {
        flex: 1;
        width: 100%;
        height: 36px;
        padding: 7px 15px;
    }
}

.autocompleteField {
    width: 70%;
    height: 36px;
}

.fieldIntegrationTabSize {
    width: 74% !important;
}

.fieldIntegrationFullSize {
    width: 100% !important;
}

.fieldIntegrationSync {
    width: 68%;
    border-radius: 5px;
    padding: 5px 8px;
    border: 1px solid getColor('secondary', 'light');
}

.dropdownField {
    width: 70% !important;
    height: 36px;
    border: 1px solid getColor('secondary', 'light');
    border-radius: 5px;
}

.dropdownFieldFull {
    width: 100% !important;
    border-radius: 5px;
}

.fieldRow {
    width: calc(70% + 48px);
    justify-content: flex-end;
    flex-wrap: wrap;
    display: flex;
}

// .autocompleteField {
//   flex: 1;
//   margin-right: 10px;
//     > div:first-child {
//         padding-left: 8px;
//         padding-right: 8px;
//     }
// }

.rowOptions {
    display: flex;
    width: 70%;
}

.selectedHourPreview {
    align-self: center;
}

.warningText {
    color: #ff0000;
    font-size: 14px;
    padding-right: 20px;
}

.confirmBtn {
    background-color: #fbf4f4;
    border: 1px solid getColor('primary');
    border-radius: 5px;
    color: #f8711e;
}

.confirmBtn:hover {
    border: 1px solid #eb8007;
    background: #ff8800;
    color: #fff;
}

.labelField {
    padding-left: 10px;
    white-space: nowrap;
    font-size: 14px;
}

.selectExtraChildPadding {
    & > div:first-child {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.flexFill {
    flex: 1;
}

.wrapperFields {
    width: 100%;
    height: 50px;
    padding: 10px 0 0 0;
    display: flex;
    color: getColor('primary', 'dark');
    justify-content: space-between;
    align-items: flex-end;
    &.tactic {
        column-gap: 14px;
        padding: 0px;
    }
}

.wrapperActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

.wrapperPrefixFields {
    display: flex;
    column-gap: 14px;
    width: 70%;
}

.wrapperBtnPlus {
    margin-bottom: 3px;

    .btnTexts {
        position: relative;
        top: -1px;
    }

    button {
        position: relative;
        top: 2px;
        width: 35px;
        height: 35px;
        background: getColor('primary');

        i {
            position: relative;
            top: -7px;
            left: -1px;
        }

        i:before {
            background-color: #f0f8ff;
            color: #f8710e;
            border-radius: 100px;
            font-size: 12px;
            font-weight: 700;
            padding: 4px;
        }
    }

    button:disabled {
        background: getColor('secondary', 'light') !important;
        color: #fff !important;
        border: 1px solid #fff;
        background-color: #fff;

        i::before {
            color: getColor('secondary', 'light');
        }

        .k-i-check::before {
            color: #fff !important;
        }
    }
}

.floatLeft {
    float: left;
    margin-right: 10px;
}

.checkIntegrationSetup {
    width: 100%;

    input:checked {
        background-color: forestgreen;
        border-color: forestgreen;
    }

    input:focus {
        border-color: forestgreen !important;
        box-shadow: 0 0 0 2px rgba(33, 248, 14, 0.3) !important;
    }
}

.wrapperCheckbox {
    width: 70%;
    label {
        padding-left: 5px;
        font-weight: bold;
    }
}

.green {
    color: forestgreen;
}

.red {
    color: #ff0000;
}

.checkFix {
    position: relative;
    top: -10px;
}

.warningMsg {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: calc(100% - 40px);
    padding: 20px;
    box-sizing: border-box;
    color: getColor('primary');
    text-align: center;
    border-radius: 10px;
    position: absolute;

    span {
        font-size: 50px;
    }

    p {
        margin: 0;
        margin-top: 10px;
    }
}

.selectExtraSize {
    width: 100%;
    padding-left: 15px;
}

.disabled,
.disabled:hover {
    background: #aaaaaa !important;
    color: #ffffff !important;
    border: 1px solid #ccc !important;
}

.extra {
    width: unset;
    padding: 5px 9px;
}

.syncSize {
    width: 75% !important;
}

.required_field {
    color: red;
}

.headerTab {
    display: flex;
    column-gap: 14px;
    width: 100%;
}

.tacticMapping {
    padding: 0px 10px;
}

.fullWidth {
    width: calc(100% - 15px) !important;
}

.warnningMessage {
    width: 100%;
    border: 1px solid #ba550b;
    border-radius: 510px;
    color: #ba550b;
    padding: 5px 10px;
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
    background-color: #fff4ec;
}

.headerTitle,
.headerTitleFull {
    flex: 1;
    width: calc(50% - 15px);
    min-height: 20px;
    border-bottom: 1px solid #eb8007;
    border-radius: 5px;
    padding: 10px 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    //   margin-right: 15px;

    span {
        font-weight: 500;
        color: #eb8007;
    }
}

.headerTitleFull {
    position: relative;
    top: 2px;
    background: #fff;
    width: 100%;
    margin-top: 20px;
    text-align: center;
    border: 1px solid #eb8007;
    border-radius: 5px 5px 0 0;
    margin-bottom: 0px;
    z-index: 1;
    &.headerTitleFullDisabled {
        border: 1px solid getColor('secondary', 'light');
        span {
            color: getColor('secondary', 'light');
        }
    }
}

.paused {
    display: none;
}

.fieldHolderToolTip {
    flex: 1;
    position: relative;
    min-height: 20px;
}

.positionRelative {
    position: relative;
}

.toolTipMPR {
    width: 300px !important;
}

.warn {
    top: 2px;
    right: -25px;
}

.warnCustomGoal {
    top: 10px;
    right: 10px;
}

.toolTipInput {
    width: 100% !important;
}

.tactic {
    flex: 1;
    padding: 7px 15px;
}

.gridCont {
    margin-top: 30px;

    thead {
        display: none;
    }
}

.errorAlert {
    position: relative;
    clear: both;
    top: 12px;
}

.autoCompleteFieldTactic {
    flex: 1;
}

.zIndex1 {
    z-index: 1;
}
