.container {
    display: flex;
    column-gap: .5rem;

    .input {
        flex-grow: 1;
    }
}

.selected {
    font-weight: 700;
}
