@import '/src/styles';

.legend {
    box-shadow: 0 4px .5rem 0 rgb(0 0 0 / 2%), 0 6px .625rem 0 rgb(0 0 0 / 2%);
    position: absolute;
    font-size: .75rem;
    top: 0;
    left: 0;
    width: 10.625rem;
    color: #f36f0e;
    background: #FFF;
    border: 1px solid #f36f0e;
    border-radius: 1.25rem;
    padding: 1.25rem;

    &__line:last-of-type {
        margin: unset;
    }

    &__line {
        display: block;
        margin-bottom: 5px;
    }

    &__text {
        position: relative;
        padding-left: 1.25rem;
        user-select: none;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 0.9375rem;
            height: 0.9375rem;
            border-radius: 6.25rem;
            transform: translateY(-50%);
        }

        &--parent-color::before {
            background-color: #EEEEEE;
            border: 1px solid #CCCCCC;
        }

        &--current-color::before {
            background-color: getColor(auxRed, darker);
            border: 1px solid getColor(primary);
        }

        &--children-color::before {
            border: 1px solid getColor(primary, base);
            background-color: rgb(251, 244, 244);
        }
    }
}
