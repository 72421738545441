#associtionGrid ::-webkit-scrollbar {
    width: 0px;
}

.gridCont {
    padding: 0 30px;
    box-sizing: border-box;
    margin-bottom: 30px;

    td {
        white-space: nowrap;
        padding: 10px 15px !important;
        font-size: 13px;
    }

    thead th {
        font-weight: 700;
        font-size: 16px;
        color: #f36f0e;
        padding: 15px;
    }

    .k-filter-row th {
        background: #fff;
    }

    .k-filtercell-operator {
        width: 24%;
    }

    .k-filtercell-operator {
        display: none;
    }

    th[title='Status'],
    th[title='Force Sync'] {
        text-align: center !important;
    }

    [role='presentation'] {
        overflow: hidden;
    }
}
