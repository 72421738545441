.tagWrapper {
    display: flex;
    column-gap: .5rem;
    flex-wrap: nowrap;
    overflow: scroll hidden;
    width: 100%;
    height: 25px;

    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tag {
    white-space: nowrap;
}
