@import '../../styles';

.backModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(66, 66, 66, 0.35);
    z-index: 2;

    .modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        background: #fff;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;

        .centered {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 100px;

            h2 {
                text-align: center;
                margin-bottom: 20px;
            }

            .message {
                width: 100%;
                min-height: 20px;
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }
}
