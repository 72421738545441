.modalWrapper {
    width: 380px !important;
}

.modalContent {
    font-size: 1rem;
    white-space: normal;
}

.confirmButtonWrapper {
    display: flex;
    justify-content: center;
    margin: 30px 0px 0px 0px;
    font-size: 1rem;
}
