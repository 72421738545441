.tooltip-container {
    display: block;
    position: relative;
}

.cont {
    position: absolute;
}

.toolTip {
    position: absolute;
    bottom: 0;
    left: -5px;
    width: 120px;
    min-height: 20px;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 12px;
    background: #fff;
    border-radius: 10px 10px 10px 0;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
}

.infoCustGoals {
    position: absolute !important;
    top: 0;
    right: 0;
    cursor: pointer;
}
