.header {
    display: flex;
    flex-direction: row;
}

.btnCont {
    width: calc(100% - 300px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    button.large {
        white-space: nowrap;
    }
}
