@import '../../styles';

.container {
    text-decoration: none!important;
}

.contentIconImg {
    position: relative;
    width: 130px;
    height: 130px;
    background: linear-gradient(180deg, #ffffff 60%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin: 0px auto 20px;
    border: 1px solid #f2f2f2;
    text-decoration: none;

    .iconBaseStyle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #6d7587;
      font-size: 50px;
      height: 80px;
    }

    &:hover {
        transition: 0.5s all;
        background: linear-gradient(245deg, #ffffff 80%, rgba(255, 255, 255, 0) 100%);
        box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
        border: 1px solid #f2f2f2;
    }
}

.contentLabel {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    margin: unset;
    color: getColor("secondary");
    text-decoration: none;
}
