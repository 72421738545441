@import "./bootstrap-custom.scss";
@import './index.scss';

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-header > .project-name {
  text-align: left;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
}

.app-header > .nav {
  float: righnpmt;
}

.content {
  min-height: 100vh;
}

.logo {
  font-weight: 700;
  font-size: 24px;
}

.welcome {
  text-align: center;
  font-weight: 900;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
}

.sub-header {
  text-align: center;
  font-weight: 400;
  letter-spacing: 0;
  color: #919191;
  opacity: 1;
  margin-bottom: 4rem;
}

.get-started {
  text-align: center;
  font-weight: 900;
  font-size: 78px;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  margin-bottom: 1rem;
}

.kendoka {
  width: 12.5rem;
  height: 12.5rem;
  opacity: 1;
  margin-top: -2rem;
}

.change-theme {
  font-size: 14px;
  color: #9098a5;
}

.components-list > p > a {
  margin-left: 0.6em;
}
.components-list > p > img {
  width: 1.25rem;
  height: 1.3125rem;
}
.footer {
  font-size: 11px;
  background: #646871 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.footer .links {
  text-align: left;
  letter-spacing: 0.33px;
  color: #ffffff;
  font-weight: 700;
  opacity: 1;
}
.divider {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  opacity: 0.2;
}

.copyright {
  letter-spacing: 0.33px;
  color: #ffffff;
  opacity: 0.6;
}

.k-widget.k-menu {
  height: 100%;
}

.k-widget.k-dropdown,
.k-list .k-item {
  text-transform: uppercase;
}

.social img {
  margin-left: 3rem;
}

.title {
  position: relative;
  top: 3px;
  left: -5px;
  font-weight: bold;
  font-size: 22px;
  color: getColor('primary')!important;
  letter-spacing: -2px;
  cursor: pointer;
  text-decoration: none!important;

  span {
    letter-spacing: 0;
    color: #000000;
    font-size: 16px;
    margin-right: 10px;
  }
}
.custom-toolbar {
  position: fixed;
  background: #FFF;
  z-index: 2;
  width: 100%;
  line-height: 10px;
  border-bottom: inset;
  border-bottom-width: 3px;
  border-color: getColor('primary');
  padding: 3px 8px;
  color: #656565;
  height: 70px;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}
.k-drawer-container {
  height: calc(100vh - 70px);
}
.k-drawer-item {
  user-select: none;
  border-right: 1px solid #e7e7e7;
}
.k-drawer-item .k-icon {
  font-size: 20px;
}
.k-drawer-content {
  position: relative;
  min-height: calc(100vh - 70px) !important;
  z-index: 0;
}
.k-card-image {
  max-height: 240px;
}
.k-drawer-item.sub-item {
  padding-left: 20px;
}
.k-card-subtitle {
  margin: 0;
  line-height: 1.5;
}

.toolbar-brand {
  width: 80px;
  padding-bottom: 10px;
  margin-left: 30px;
  cursor: pointer;
}

.title-section-marker {
  color: getColor("primary", "base");
  font-weight: bold;
}
.title-section {
  font-weight: 400;
}

.client-selector {
  font-size: 13px;
  font-weight: 300;
  color: getColor('secondary');
  width: max-content;
}

.link-text {
  color: #3e3f37;
  font-weight: 600;
  text-decoration: revert;
}

.dashStyle {
  letter-spacing: -1px;
  color: #666666;
}

.title-main {
  font-weight: 300;
  padding-right: 15px;
  margin: unset;
}

.title-main-sub {
  font-weight: 300;
  margin: unset;
}

.icon-alert {
  font-size: 100px !important;
  color: #3e3f374d !important;
}

.integrations-word {
  color: getColor('primary') !important;
  font-weight: bold;
}

.clients-list {
  width: 350px !important;
}

.Toastify__toast--success {
  background-color: #0b6835;
}

.Toastify__close-button--success {
  text-align: end!important;
  padding-right: 10px!important;
}

.Toastify__close-button--error {
  text-align: end!important;
  padding-right: 10px!important;
}

.backLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(256, 256, 256, 0.8);
  z-index: 4;

  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.backModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(66, 66, 66, 0.35);
  z-index: 2;

  .modal {
    width: 500px;
    min-height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05),
      0 6px 20px 0 rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 35px;
    color: #666666;
  }
}
button {
  font-weight: 700;
}

.question {
  width: 100%;
  min-height: 20px;
  float: left;
  padding-bottom: 0;
  box-sizing: border-box;
  font-size: 21px;
  text-align: center;
  color: getColor('primary', 'dark');

  span {
    font-weight: bold;
  }
}

.btnCont {
  width: 100%;
  padding-top: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse;
}

button {
  transition: all 0.2s;
  width: 190px;
  min-height: 20px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ffffff;
  background: #bd3c3c;
  border: 1px solid getColor('primary', 'dark');
  text-align: center;
  color: #FFF;
  float: right;
}

.cancelBtn {
  margin-left: 20px;
}
.cancelBtn:hover {
  border: 1px solid #d85e5e;
  background: #c77d60;
}

.confirmBtn {
  background-color: #fbf4f4;
  border: 1px solid #f98733;
  border-radius: 5px;
  color: getColor('primary');
}

.confirmBtn:hover {
  border: 1px solid #c47a7a;
  background: #ffe8e8;
  color: #d85e5e;
}

.wrapper-cont {
  display: flex;
  justify-content: flex-start;
}

.contIcon {
  width: 135px;
  cursor: pointer;
  margin: 0 40px 40px 0;
}

.container-fluid {
  position: relative;
  z-index: 0;
}

.IconsCont {
  width: 100%;
  padding: 0 30px 30px 30px;
  display: flex;
}

.pageTitleCont {
  width: 100%;
  min-height: 110px;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  h2 {
    padding-right: 20px;
  }
}

.gridCont {
  width: 100%;
  padding: 0 30px 30px 30px;
  box-sizing: border-box;
}

.contIconImg {
  position: relative;
  width: 130px;
  height: 130px;
  background: linear-gradient(180deg, #ffffff 60%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid #f2f2f2;

  .k-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #6d7587;
    font-size: 50px;
    height: 80px;
  }
}

.contIconImg:hover {
  transition: 0.5s all;
  background: linear-gradient(245deg, #ffffff 80%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #f2f2f2;
}

.k-button.k-button-icon[title="Clear"] {
  display: none !important;
}

.contIconText {
  text-align: center;
  font-size: 14px;
}

.companyLogo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.admLogo {
  width: 350px;
  float: left;
}

.dropCustomer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.avatar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}

.actionsBtn {
  text-align: center !important;
  color: #565656;

  .green {
    color: #20a100;
  }

  .red {
    color: #ff0000;
  }

  .k-icon {
    box-sizing: border-box;
    padding: 0 10px;
  }
}

.pointer {
  cursor: pointer;
}

/* .k-grid {
  border: 1px solid #ffbe8e!important;
} */

.k-grid-header .k-header {
  border-bottom: 1px solid #ffdbc1 !important;
}

.grid {
  min-height: "0";
  width: "100%";

  .k-grid-header {
    padding: 0 !important;
  }

  .k-grid-content {
    overflow-y: auto;

  }

  td {
    white-space: nowrap;
  }
}

.warningText {
  width: 100%;
  padding: 30px;
  text-align: center;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  background-color: #f2f2f2;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 5px;
  background: getColor('primary');
/*   border: 8px solid #FFF; */
  border-radius: 10px;
}

// Scrollbar styles to mozilla firefox
* {
  scrollbar-color: getColor('primary') #fff;
  scrollbar-width: thin;
}

.logout {
  width: 40px !important;
  height: 40px !important;
  cursor: pointer;
  color: #424242;
  font-size: 14px !important;
}

.clientSingle {
  color: getColor('primary');
  font-weight: bold;
  font-size: 20px;
}

.k-grid-header {
  padding-right: 0!important;
}

.mainCont {
  position: relative;
  top: 70px;
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 70px);
  align-items: stretch;
  overflow-y: auto;
  background-color: #fcfcfc;
}

.content {
  flex: 1;
}

.menu {
  min-width: 305px;
  flex: 0 0;
  background-color: #f9f8f7;
  border-right: 5px solid #FFF;
  transition: all 200ms ease-in-out;
  color: getColor('secondary', 'dark');
  overflow-x: hidden;

  .subLink {
    padding-left: 35px!important;
  }

  ul{
    width: 300px;
    list-style: none;
    padding: 0;
    margin: 0;

    li{
      display: flex;
      flex-direction: column;

      .linkList {
        position: relative;
        font-weight: 300;
        padding: 12px 10px;
        box-sizing: border-box;
        padding-left: 30px;
        border-top: 1px solid #f9f8f7;
        border-bottom: 1px solid rgb(233, 229, 229);
        cursor: pointer;

        span {
          padding-left: 20px;
          font-weight: 400;
        }

        i {
          position: relative;
          top: -1px;
          left: -14px;
        }
      }

      .linkList:hover {
        background: #fcfcfc;
      }
    }
  }
}

.routeContent {
  flex-grow: 1;
  align-self: stretch;
}

.selectedLink {
  background: #fcfcfc!important;
  border-top: 1px solid getColor('primary')!important;
  border-bottom: 1px solid getColor('primary')!important;
}

.selectedLinkHome {
  background: #fcfcfc!important;
  border-bottom: 1px solid getColor('primary')!important;
}

.sectionOpen {
  transition: all 0.2s;
  position: absolute!important;
  top: 16px!important;
  left: unset!important;
  right: 20px!important;
  transform: rotate(90deg);
}

.sectionClose {
  transition: all 0.2s;
  position: absolute!important;
  top: 16px!important;
  left: unset!important;
  right: 20px!important;
  transform: rotate(0deg);
}

.retract {
  min-width: 55px!important;
  flex: 0 0!important;
  transition: all 200ms ease-in-out!important;
  overflow-x: hidden;
}

.userName {
  font-size: 16px;
  font-weight: 300;
  padding-right: 10px;
}

.k-icon-24 {
    font-size: 1.5rem!important;
}

.k-icon-32 {
    font-size: 2rem!important;
}

.k-icon-64 {
    font-size: 4rem!important;
}

.accessibility-keyboard {
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: solid;
    outline-width: 2px;
    transition: outline-color 60ms linear;
    &:focus {
        outline-color: getColor('primary', 'dark');
    }
    &:hover {
        outline-color: transparent;
    }
}
