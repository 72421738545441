@import '../../../../../styles';

.attributeConfigurationForm {
    height: 100%;
    width: 100%;
}

.attributeSettingsSection {
    padding: 12px;
    height: 100%;
}

.rightSidePanel {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 992px) {
        border-left: 1px solid getColor('secondary', 'light');
    }
}

.labelCheckboxFields {
    margin-left: 2em;
}

.actionButtonsContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0px -8px;

    .actionButtonWrapper {
        width: 50%;
        padding-right: 20px;
        margin-top: 15px;
        margin-bottom: 20px;

        &:first-child {
            order: 0;
        }

        @media screen and (min-width: break('xl')) {
            padding: 0.5rem 0.875rem;
        }
    }

    .actionButtonWrapper:last-child {
        padding-right: 0;
    }

    .actionButton {
        width: 100%;
    }
}

.changeAttributeTypeActionButtons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;
}
