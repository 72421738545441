@import '../../../../../../styles';

.option {
    display: flex;
    column-gap: .5rem;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 150ms ease;
    padding: .25rem .75rem;
    border-radius: 8px 0 0 8px;

    &:hover {
        background-color: rgba(getColor('secondary', 'light'), .2);
    }

    &.selected {
        color: getColor('primary');
        background-color: rgba(getColor('primary', 'light'), .3);
        font-weight: 500;

        &:hover {
            background-color: rgba(getColor('primary', 'light'), .5);
        }
    }

    .option__text {
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.copy {
    padding-right: 10px;
    font-size: 12px;
    font-style: italic;
}

.copy:hover {
    text-decoration: underline;
}
