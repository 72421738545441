.checkboxBaseStyle {
    position: relative;
    top: 3px;
    width: 30px;
    appearance: none;
    width: 17px;
    height: 17px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    color: transparent;
    background-color: #ffffff;
    cursor: pointer;
}

.checkboxBaseStyle:checked {
    border-color: #f8710e;
    color: #ffffff;
    background-color: #f8710e;
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="none" stroke="white" stroke-linecap="square" stroke-linejoin="square" stroke-width="2" d="M3,8 l3,3 l7-7"/%3e%3c/svg%3e');
}

.checkboxBaseStyle:focus {
    border-color: #f8710e;
    box-shadow: 0 0 1px 2px rgba(255, 99, 88, 0.2);
}
