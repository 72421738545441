@import '../../../styles';
$border-radius: 5px;

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}

.optionsContainer {
    position: absolute;
    left: 0;
    z-index: 10;

    background-color: white;
    border: 1px solid #e9e9e9;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    display: none;
    height: 100%;
    width: 100%;
    max-height: 200px;
    overflow: hidden;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);

    animation: growDown 250ms ease-in-out forwards;
    transform-origin: top center;

    &.renderTop {
        transform-origin: bottom center;
    }

    &.optionsVisible {
        display: block;
    }
}

.optionsUL {
    position: relative;
    list-style: none;
    padding: 2px 0;
    height: 100%;
    overflow-y: scroll;
}

@keyframes rotateX {
    0% {
        opacity: 0;
        transform: rotateX(-90deg);
    }
    50% {
        transform: rotateX(-20deg);
    }
    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

.optionLI {
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    padding: 3px 0.5em;
    background-color: #f8f8f8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 1rem;

    &:nth-child(odd) {
        background-color: white;
    }
    &:hover {
        background-color: getColor('primary');
        color: white;
    }
    &:focus {
        background-color: rgba(getColor('secondary', 'light'), 0.5);
        outline: none;
    }
    &.selected {
        background-color: rgba(getColor('primary'), 0.7);
        color: white;
    }
}
