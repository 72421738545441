@import '../../../../../../styles';

.option {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    cursor: pointer;
    transition: background-color 150ms ease;
    padding: .25rem;
    border-radius: 8px 0 0 8px;

    &:hover {
        background-color: rgba(getColor('secondary', 'light'), .2);
    }

    &.selected {
        color: getColor('primary');
        background-color: rgba(getColor('primary', 'light'), .3);
        font-weight: 500;

        &:hover {
            background-color: rgba(getColor('primary', 'light'), .5);
        }
    }

    .option__text {
        font-size: 1rem;
        line-height: 1.3rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.checkbox {
    top: 0;
    user-select: none;
    aspect-ratio: 1;
}
