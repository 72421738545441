@import '../../../styles';

$transitionSettings: 200ms linear;

.container {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}

.inputBaseStyle {
    appearance: none;
    display: block;

    width: 100%;
    padding: 0.5em 1em;
    border: 1px solid getColor('secondary', 'light');
    border-radius: 5px;

    color: getColor('secondary', 'dark');
    background-color: #fff;
    @include outline;

    transition: outline-color $transitionSettings, border-color $transitionSettings;

    &:focus {
        border-color: getColor('primary', 'light');
        @include focusOutline;
    }

    &:disabled {
        background-color: getColor('disabled', 'light');
        color: getColor('disabled');
    }
}

.error {
    border: 1px solid getColor('auxRed');
    background-color: getColor('auxRed', 'lighter');
    color: getColor('auxRed');
    &::placeholder {
        color: rgba(getColor('auxRed'), .8);
    }
}

.errorMessage {
    color: getColor('auxRed');
}

.textInputWrapper {
    position: relative;
}

.traillingIcon {
    cursor: text!important;
    position: absolute!important;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
}

.iconRight {
    padding: 0.5em 3em 0.5em 1em;
}
