
#intSetup{
    &.scrollIsVisible {
        div[class='k-grid-container'] {
            @-moz-document url-prefix() {
                width: calc(100% + 8px);
            }
            width: calc(100% + 5px);
        }
    }
}
.gridCont {
    padding: 0 30px;
    box-sizing: border-box;
    margin-bottom: 30px;

    td {
        white-space: nowrap;
        padding: 10px 15px !important;
        font-size: 13px;
    }

    button[title='Clear'] {
        display: none;
    }

    thead th[aria-label='Filter'] {
        padding: 8px;
        color: #888;
    }

    thead th {
        font-weight: 700;
        font-size: 16px;
        color: #f36f0e;
        padding: 15px;
    }

    .k-filter-row th {
        background: #fff;
    }

    .k-filtercell-operator {
        width: 24%;
    }

    .k-filtercell-operator {
        display: none;
    }

    th[title='Status'],
    th[title='Force Sync'] {
        text-align: center !important;
    }

    [role='presentation'] {
        overflow: hidden;
    }
}

.integrationSetup {
    span[role='presentation']::before {
        border-radius: 100px;
    }
}

.displayNone {
    display: none;
}

.cursorPointer {
    cursor: pointer;
}

.actionsBtn {
    text-align: center !important;
    color: #565656;
}

.upload {
    font-size: 70px !important;
    color: #f8710e;
}

.gridCont .red {
    color: #ff0000 !important;
    font-weight: 500;
}

.gridCont .redCont {
    padding: 2px 5px;
    text-transform: uppercase;
    border: 1px solid #ffa8a8;
    border-radius: 5px;
    font-size: 9px;
    text-align: center;
    color: #ff6464;
    font-weight: 500;
}

.gridCont .greenCont {
    padding: 2px 5px;
    text-transform: uppercase;
    border: 1px solid #3dc63d;
    border-radius: 5px;
    font-size: 9px;
    text-align: center;
    color: #3dc63d;
    font-weight: 500;
}

.gridCont .orangeCont {
    padding: 2px 5px;
    text-transform: uppercase;
    border: 1px solid #ca9400;
    border-radius: 5px;
    font-size: 9px;
    text-align: center;
    color: #ca9400;
    font-weight: 500;
}

.gridCont .blueCont {
    padding: 2px 5px;
    text-transform: uppercase;
    border: 1px solid #89c1c9;
    border-radius: 5px;
    font-size: 9px;
    text-align: center;
    color: #89c1c9;
    font-weight: 500;
}

.errorModal {
    background-color: #c05151 !important;
    color: #fff !important;

    .btnCont {
        justify-content: center;
        margin-top: 40px;
    }
    span,
    div {
        color: #fff !important;
        border-color: #fff !important;
    }
    button {
        background: #fff;
        color: #cc6f6f;
        border: 1px solid #cc6f6f;
    }

    button:hover {
        background: #d8a5a5;
    }
}

.formats {
    font-size: 12px;
}

.linkInactive {
    color: #aaa;
    cursor: unset !important;
    text-decoration: unset !important;
}

.btnCont {
    width: 100%;
    min-height: 20px;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.uploadModalConfig .btnCont {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 20px;
    display: flex;
    justify-content: center !important;
}

.deleteModal {
    width: 480px !important;
}

.text-upload {
    text-decoration: underline;
    font-weight: bold;
    color: #f8710e;
}

.integrationButton {
    align-self: flex-start;
    span {
        margin-right: 5px;
    }
    button {
        width: 230px;
        font-weight: bold;
        padding: 10px 30px;
    }
}

.question {
    width: 100%;
    min-height: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    font-size: 21px;
    text-align: center;
    color: #ba550b;
    margin-bottom: 20px;

    span {
        font-weight: 700;
    }
}

.wrapperQuestion {
    width: 100%;
    height: calc(100% - 80px);
    position: absolute;
    border: 2px dashed #66666636;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    color: #ba550b;
    align-items: center;
    transition: all 0.2s ease-in-out;
    padding: 25px;

    p {
        text-align: center;
    }
}

.historyModalConfig {
    width: 90% !important;
}

.statusIntModalConfig {
    .btnCont {
        justify-content: center;
    }
}

.rescheduleIntModal {
    .btnCont {
        margin-top: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .calendar {
            width: calc(100% - 5px);
            margin-top: 20px;
            margin-top: 0;
        }

        .labelDate {
            font-size: 16px;
            font-weight: 300;
            margin: 10px 0;
        }

        .autocompleteField {
            margin-top: 20px;
            width: 100%;
        }

        .labelDate:first-child {
            margin: 0;
        }

        button {
            margin-top: 20px;
        }
    }
}

.uploadModalConfig {
    width: 500px !important;
    height: 350px !important;
}

.linkToForceSync {
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
}

.linkToForceSync:hover {
    text-decoration: underline;
}

.afterDoc {
    background-image: unset;
    background: #fff;
}

.dragOn {
    background: rgb(226, 226, 226) !important;
}

.alignCenter {
    text-align: center !important;
}

.confirmBtn {
    background-color: #fbf4f4;
    border: 1px solid #f8710e;
    border-radius: 5px;
    color: #f8711e;
}

.iconSizes {
    padding: 0 12px !important;
    font-size: 18px !important;
    cursor: pointer;
}

.warningText {
    width: 100%;
    padding: 45px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
