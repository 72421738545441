@import '/src/styles';

.export-data {
    padding-top: .5rem;

    &__download-wrapper {
        &--hidden {
            opacity: 0;
        }
    }

    &__button {
        flex: 1;
    }

    & > :not(:last-child) {
        margin-bottom: 1.5rem;
    }

    &__checkbox-wrapper {
        display: flex;
        gap: 1rem;
        align-items: center;

        label {
            cursor: pointer;
            user-select: none;
            margin: 0;
        }

        input {
            top: 0;
        }
    }
}
