@import '../../../../styles';

.attributeConfigurationModal {
    position: relative;
    width: 90% !important;
    height: 90% !important;
    background-color: #f9f9f9 !important;
    z-index: 0;
}

.modalContent {
    position: relative;
    width: 100%;
    height: calc(100% - 67px - 50px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 15px 15px 15px;
    box-sizing: border-box;
    border: 1px solid getColor('secondary', 'light');
    background: #fff;
    border-radius: 0px 5px 5px 5px;
}

section {
    padding: 12px;
}

.selectedAttr {
    display: flex;
    position: absolute;
    top: 100px;
    right: 40px;
    z-index: 2;

    b {
        position: relative;
        top: 4px;
        left: -20px;
        color: #f36f0e;
    }

    .attrSelection {
        width: 250px;
    }
}

