@import '/src/styles';

.modal {
    width: calc(100% - 5rem)!important;
    height: calc(100% - 5rem);
    background-color: #f4f0f0!important;

    .zIn, .ZOut, .center, .btn{
        position: absolute;
        bottom: 0.625rem;
        right: 0;
        width: 6.25rem;
        height: 3.125rem;
        padding: unset;
        background: #FFF;
        border-radius: 6.25rem;
        box-shadow: 0 4px .5rem 0 rgb(0 0 0 / 2%), 0 6px 0.625rem 0 rgb(0 0 0 / 2%);
    }

    .zIn:hover, .ZOut:hover, .center:hover, .btn:hover{
        background: #fbf4f4;
    }

    .zIn {
        top: 15.625rem;
        left: 0;
        font-size: 0.75rem;
    }

    .ZOut {
        top: 19.375rem;
        left: 0;
        font-size: 0.75rem;
    }

    .center {
        top: 9.375rem;
        left: 0;
        font-size: 0.75rem;
    }

    .btn{
        bottom: 0.625rem;
        right: 0;
    }
}
