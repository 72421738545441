.deleteToggleModal {
    width: 380px !important;
}

.buttonCont {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
