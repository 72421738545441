@import '../../../../../styles';

.container {
    position: relative;
    display: flex;
    column-gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    padding: 12px;
}

.col {
    position: relative;
    flex: 0 0 auto;
    width: 100%;
    z-index: 1;
    @media screen and (min-width: 820px) {
        width: calc(50% - 1rem);
    }
}

.fullCol {
    flex: 0 0 100%;
}

.card {
    height: 100%;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    column-gap: 2rem;

    &__right-content {
        display: flex;
        column-gap: 1rem;
    }
}

.disabled {
    background-color: rgba(getColor('secondary', 'light'), .4);
    border-color: #CCC;
}

.titleDependency {
    color: #717785;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -1px;
}

.grey {
    h3, span{
        color: #c3c3c1;
    }
}

.zIndex {
    z-index: 2;
}

.parentWarn {
    display: flex;
    align-items: center;
    position: relative;
    top: 37px;
    width: 100%;
    padding: 7px 10px;
    border-radius: 8px;
    color: #f80e0e;
    font-size: 12px;
    border: 1px solid #f80e0e;
    animation: mymove 2s infinite;
    background: #ffecec;

    .warnText {
        padding-left: 0px;
        font-weight: bold;
        font-size: 14px;
    }

    .arrow {
        position: relative;
        left: -10px;
    }

    @keyframes mymove {
        0% {opacity: 1;}
        50% {opacity: 0.05;}
        0% {opacity: 1;}
    }
}

.parentOk {
    display: flex;
    align-items: center;
    position: relative;
    top: 37px;
    width: 100%;
    padding: 7px 10px;
    border-radius: 8px;
    color: #00c445;
    font-size: 12px;
    border: 1px solid #00c445;
    background: #e4ffed;

    .okText {
        padding-left: 17px;
        font-weight: bold;
        font-size: 14px;
    }

    .ok {
        padding-left: 5px;
    }
}

.relationship {
    position: absolute;
    top: 50%;
    left: -52px;
    padding: 10px 15px;
    transform: translateY(-50%);
    border-radius: 150px;
    background: #FFF;
    border: 1px solid #CCC;
    color: #f8710e;

    .icon {
        font-size: 40px;
    }
}

.success {
    color: #666666;
    background: #FFF;
}

.btnCont {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;
}

.orangeBold{
    color: #f36f0e;
}

.modalCopy {
    position: relative;
    width: 460px!important;
    display: flex;
    flex-direction: column;

    .qttNumber {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0.8rem;
        line-height: 1rem;
        font-weight: 500;
        color: #f8710e;
        border: 2px solid #f8710e;
        background-color: #fff8f3;
        border-radius: 100px;
        padding: 2px 0.7rem;
    }

    .copyItem {
        text-align: center;
        padding: 10px 30px 10px 20px;
        border: 1px solid #ffddc5;
        border-radius: 50px 300px 0 0;
        border-bottom: unset;
        font-size: 22px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #f36f0e;

        span {
            position: relative;
            top: -3px;
            right: 3px;
            color: #717785;
            font-weight: 400;
            font-size: 11px;
        }
    }
}

.InsertOptions {
    display: flex;
    button {
        width: 50%;
        background: #FFF!important;
        font-weight: 400;
        border: 1px solid #ffddc5;
        border-radius: unset;
        font-size: 11px;
        text-transform: uppercase;
    }

    .selectedBtn {
        background-color: #fff9f5!important;

        color: #f36f0e;

    }

    .over {

        border-right: unset;
    }
}

.copyCont {
    display: flex;
    gap: 20px;

    .copyDecr {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #c3c3c1;
        padding: 40px 60px;
    }

    .icon {
        font-size: 180px;
        margin-bottom: 20px;
    }

    .copyList {
        flex-grow: 1;

        .kIcon {
            top: 22px;
        }
    }

    .marginBt10 {
        margin-bottom: 10px;
    }

    .marginTp10 {
        margin-top: 10px;
    }

    .search {
        border: 1px solid #ffddc5;
        border-radius: 0;
        border-top: unset;
        padding: 10px 20px;
        margin-bottom: 15px;
        border-right: unset;
        border-left: unset;
    }

    .search:focus {
        border: 1px solid #FFF;
        border-radius: 0;
        border-top: unset;
        padding: 10px 20px;
        margin-bottom: 15px;
    }
}
