@import '../../styles';

.container {
    position: relative;
    display: flex;
    min-height: 330px;
    flex-direction: row;
    background-color: rgba(238, 238, 238, 0.2);
    border: 1px solid #CCC;
    padding: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.14);
    transition: all ease 1000ms;
}

.cardHeader {
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px .5rem;
    border-right: 1px solid #CCC;

    h3 {
        position: relative;
        bottom: -150px;
        width: 300px;
        font-size: 1.7rem;
        line-height: 1.125rem;
        margin: 0px 0px 4px 0px;
        color: getColor('secondary');
        padding-top: 20px;
        rotate: 90deg;
    }

    .subTitle {
        position: relative;
        top: 80px;
        bottom: -150px;
        width: 300px;
        font-size: 0.938rem;
        rotate: 90deg;
    }
}

.children {
    display: flex;
    width: calc(100% - 80px);
    padding-left: 20px;
}

.block {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(243,243,243,0.9);
    z-index: 10;

    span {
        font-size: 20px;
        font-weight: bold;
        color: #8f8f83;
    }

    .icon {
        font-size: 150px;
    }
}

.align {
    align-items: center;
}

.orange {
    color: #f8710e!important;
    text-transform: uppercase;
    font-size: 20px!important;
    letter-spacing: -1px;
}
