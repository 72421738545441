@import '../../../styles';

.sideBar {
    min-width: 305px;
    flex: 0 0;
    background-color: #f9f8f7;
    border-right: 5px solid #FFF;
    transition: all 200ms ease-in-out;
    color: getColor('secondary', 'dark');
    overflow-x: hidden;

    &.retractSideBar {
        min-width: 55px!important;
        flex: 0 0!important;
        transition: all 200ms ease-in-out!important;
        overflow-x: hidden;
    }

    .subLink {
      padding-left: 35px!important;
    }

    ul{
      width: 300px;
      list-style: none;
      padding: 0;
      margin: 0;

      li{
        display: flex;
        flex-direction: column;

        .linkList {
          position: relative;
          font-weight: 300;
          padding: 12px 10px;
          box-sizing: border-box;
          padding-left: 30px;
          border-top: 1px solid #f9f8f7;
          border-bottom: 1px solid rgb(233, 229, 229);
          cursor: pointer;

          span {
            padding-left: 20px;
            font-weight: 400;
          }

          i {
            position: relative;
            top: -1px;
            left: -14px;
          }
        }

        .linkList:hover {
          background: #fcfcfc;
        }
      }
    }
}

.selectedLinkHome {
    background: #fcfcfc!important;
    border-bottom: 1px solid getColor('primary')!important;
}

.sectionOpen {
    transition: all 0.2s;
    position: absolute!important;
    top: 16px!important;
    left: unset!important;
    right: 20px!important;
    transform: rotate(90deg);
}

.sectionClose {
    transition: all 0.2s;
    position: absolute!important;
    top: 16px!important;
    left: unset!important;
    right: 20px!important;
    transform: rotate(0deg);
}
