@import '../../styles';

.container {
    background-color: getColor('auxRed', 'dark') !important;
    color: #fff !important;

    p,
    span,
    div {
        color: #fff !important;
        border-color: #fff !important;
    }

    button {
        width: auto;
        background: #cf5c5c;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 1rem;

        &:disabled {
            opacity: 0.35;
            &:hover {
                background: transparent;
            }
        }
    }

    button:hover {
        background: #d46464;
    }
}

.modalHeader {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    h3 {
        font-size: 1rem;
        flex: 1;
        margin: 0;
    }
}

.errorStepWrapper {
    cursor: default;
    padding: 2px 12px;
    border-radius: 24px;
    border: 0.5px solid #fff;
}

.paragraphContent {
    margin: 0px 0px 24px 0px;
    font-size: 1rem;
}

.footerActionButtonsWrapper {
    display: flex;
    justify-content: space-between;
}

.confirmButton {
    background-color: #fff !important;
    color: getColor('auxRed', 'dark') !important;
}

.space {
    flex: 1;
}
