
.newItemInsertionRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid #ededed;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 1em;
}


.formAddInput {
    width: calc(25% - 10px);
    margin-right: 10px;
}

.formAddInputColor input {
    width: 100%;
    margin-right: 10px;
}

.formAddSwitch {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
}

.btnAdd {
    width: 78px;
    padding: 10px 20px;
}
