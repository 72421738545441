@import './functions.scss';

@mixin outline {
    outline-style: solid;
    outline-color: rgba(getColor('primary'), 0);
    outline-width: 2px;
    outline-offset: 0px;
}

@mixin focusOutline {
    outline-color: rgba(getColor('primary'), .3);
}