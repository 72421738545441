.checkboxWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorModal {
    width: 650px;
}
