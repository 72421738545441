@import '../../../styles';

@mixin inputOutline {
    outline-style: solid;
    outline-color: rgba(getColor('secondary'), 0);
    outline-width: 5px;
    outline-offset: 0px;
}

@mixin inputOutlineHover {
    outline-color: rgba(getColor('secondary'), 0.3);
}

$transition: all 150ms cubic-bezier(0.58, 0.42, 0.33, 1.38);

.switchBaseStyle {
    appearance: none;
    position: relative;
    cursor: pointer;

    border: none;
    background-color: getColor('secondary', 'light');
    border-radius: 2rem;

    width: 56px;
    height: 25px;
    transition: $transition;

    &::before {
        position: absolute;
        content: '';
        top: 1.5px;
        left: 1.5px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: white;
        border: 1px solid getColor('secondary', 'light');
        transition: $transition;
        box-shadow: 0 0 2px 0 rgba(getColor('secondary', 'darker'), 0.3);
        z-index: 1;
        @include inputOutline;
    }

    &::after {
        content: 'no';
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(calc(-100% - 0.5rem), -50%);
        font-size: 0.75rem;
        font-weight: 500;
        text-transform: uppercase;
        transition: $transition;
        color: getColor('secondary', 'darker');
    }

    &:checked {
        background-color: getColor('primary');
        &::before {
            left: calc(100% - 1.5px);
            transform: translate(-100%);
        }
        &::after {
            content: 'yes';
            left: 0.5rem;
            transform: translate(0, -50%);
            color: white;
        }
    }

    &:hover {
        &::before {
            @include inputOutlineHover;
        }
    }

    &:disabled {
        cursor: default;
        background-color: getColor('disabled', 'light');
        &::before {
            outline: none;
            border-color: getColor('disabled');
        }
        &::after {
            color: getColor('disabled');
        }
    }
}
