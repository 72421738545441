@import '../../../styles';

$transitionSettings: 200ms linear;

.textAreaBaseStyle {
    appearance: none;
    display: block;

    width: 100%;
    padding: 0.5em 1em;
    border: 1px solid getColor('secondary', 'light');
    border-radius: 5px;

    color: getColor('secondary', 'dark');
    background-color: #fff;
    @include outline;

    transition: outline-color $transitionSettings, border-color $transitionSettings;
    resize: vertical;

    &:focus {
        border-color: getColor('primary', 'light');
        @include focusOutline;
    }

    &:disabled {
        background-color: getColor('disabled', 'light');
        color: getColor('disabled');
        &::placeholder {
            color: getColor('disabled');
        }
    }
}
