#attributeGrid{
    &.scrollIsVisible {
        div[class='k-grid-container'] {
            @-moz-document url-prefix() {
                width: calc(100% + 8px);
            }
            width: calc(100% + 5px);
        }
    }
}

.grid {
    width: '100%';
    box-sizing: border-box;
    margin-bottom: 30px;

    td {
        white-space: nowrap;
        padding: 10px 15px !important;
        font-size: 13px;
    }

    button[title='Clear'] {
        display: none;
    }

    thead th[aria-label='Filter'] {
        padding: 8px;
        color: #888;
        font-weight: normal;
    }

    thead th {
        font-weight: 700;
        font-size: 16px;
        color: #f36f0e;
        padding: 15px;
    }

    .k-filter-row th {
        background: #fff;
    }

    .k-filtercell-operator {
        width: 24%;
    }

    .k-filtercell-operator {
        display: none;
    }

    th[title='Status'],
    th[title='Force Sync'] {
        text-align: center !important;
    }

    [role='presentation'] {
        overflow: hidden;
    }
}

.noList {
    position: relative;
    height: 200px;
}

.alignCenter {
    text-align: center !important;
}

.icon {
    cursor: pointer;
}

.center {
    text-align: center!important;
}

.yes,
.no {
    padding: 2px 5px;
    border: 1px solid #f98733;
    background-color: #fbf4f4;
    color: #f8710e;
    font-size: 12px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
}

.yes:hover,
.no:hover {
    background-color: #fce1e1;
}
