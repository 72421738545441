@import '../../../../../styles';

#addNewPicklistItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.picklistSettingsSection {
    display: flex;
    flex-direction: column;
    padding: 12px;
    height: 100%;
}

.uploadButton {
    flex-direction: row !important;
    justify-content: flex-start !important;
    gap: 16px;
    max-width: 200px !important;
    height: 46px !important;
    i {
        font-size: 18px !important;
    }
}

.downloadButton {
    background-color: getColor('primary');
    border: none !important;
    color: white;
    &:hover{
        background-color: getColor('primary', 'dark');
    }
}

.picklistHeader {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.uploadWrapper {
    display: flex;
    align-items: center;
    flex: 1;

    .uploadContent {
        display: flex;
        flex: 1;
        column-gap: 24px;
        align-items: center;

        .uploadFeedbackContent {
            display: flex;
            flex-direction: column;
        }
    }
}

.errorFormat {
    box-sizing: border-box;
    color: #f00;
}

.picklistGridWrapper {
    &.scrollIsVisible {
        div[class='k-grid-container'] {
            @-moz-document url-prefix() {
                width: calc(100% + 8px);
            }
            width: calc(100% + 5px);
        }
    }
}

.checkboxWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.picklistHeaderRightContent {
    display: flex;
    column-gap: 24px;

    .productTourIconButton {
        width: min-content;
        padding: inherit 8x;
    }
}
