.container {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
    flex-grow: 1;
}

.subTitle {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: bold;
    color: #717785;

    .type {
        position: relative;
        top: -2px;
        color: #f8710e;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: -1px;
        padding-right: 5px;
    }
}
