@import './index.scss';

body {
  margin: 0;
  font-family: "Roboto",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body, html, #root {
  height: 100%;
  color: getColor('secondary') !important;
}

table, input, select {
  color: getColor('secondary');
  background: #FFF;
  font-size: 14px!important;
}

input:disabled, select:disabled {
  background: getColor('secondary', 'lighter');
}

.group-approval{
    margin-right: 10px !important;
    float: left !important;
}
.approval-switch{
    display: inline-block !important;
}
