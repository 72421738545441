@import '/src/styles';

.relationship-error-modal {
    position: absolute;
    width: calc(100% + 2rem);
    height: calc(100% + 2rem);
    background-color: rgba(0, 0, 0, .46);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -1rem;
    top: -1rem;

    &__wrapper {
        display: flex;
        flex-direction: column;
        background-color: white;
        max-width: 500px;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .1);
    }

    &__title {
        position: relative;
        color: getColor(primary);
        margin-bottom: 2rem;

        &::after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: getColor(primary, light);
            position: absolute;
            top: calc(100% + .5rem);
            left: 0;
        }
    }

    &__paragraph {
        margin-bottom: 1rem;
    }

    &__action-buttons {
        display: flex;
        gap: 2rem;

        button {
            flex: 1;
        }
    }
}
