@import '/src/styles';

.container {
    flex: 1;
    overflow-y: auto;
    max-height: 200px;
}

.innerHeight {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
}


.warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: #717785;
    padding: 20px;
    border-radius: 8px;
    flex-grow: 1;

    h2 {
        font-size: 2em;
        color: getColor(secondary, dark);
    }

    span{
        line-height: 18px;
        font-size: 0.9em;
        color: #717785;
        color: getColor(secondary);
    }

    .icon {
        font-size: 3.75rem!important;
        color: getColor(secondary)!important;
    }
}
