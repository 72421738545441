@import '/src/styles';

.tree-element {
    cursor: pointer;
    border-radius: .625rem;
    text-align: center;
    padding: .9375rem;
    font-weight: bold;
    position: relative;
    top: .875rem;
    box-shadow: 0 4px .5rem 0 rgb(0 0 0 / 3%), 0 6px .625rem 0 rgb(0 0 0 / 3%);
    background-color: getColor(primary, lighter);
    color: getColor(primary);
    border: 1px solid getColor(primary);

    &--selected-grid-element {
        background-color: getColor(auxRed, darker);
        color: getColor(contrast, white);
        border: 1px solid getColor(primary);
    }

    &--parent-element {
        background-color: #EEEEEE;
        color: getColor(primary);
        border: 1px solid #CCCCCC;
    }

    &__warning {
        cursor: default;
        display: flex!important;
        justify-content: center;
        align-items: center;
        position: absolute!important;
        top: -.9375rem;
        right: 0;
        width: 1.7rem;
        height: 1.7rem;
        background-color: getColor(auxRed, base);
        border-radius: 2rem;
        color: getColor(contrast, white);

        span {
            font-size: 1.5rem;
        }
    }

    &__entity-type {
        border-radius: .625rem;
        text-align: center;
        padding: 0px .625rem 2px .625rem;
        font-weight: normal;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -.9375rem;
        font-size: .8125rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        background-color: getColor(primary, lighter);
        color: getColor(primary);
        border: 1px solid getColor(primary);

        &--selected-grid-element {
            background-color: getColor(auxRed, darker);
            color: getColor(contrast, white);
            border: 1px solid getColor(primary);
        }

        &--parent-element {
            background-color: #EEEEEE;
            color: getColor(primary);
            border: 1px solid #CCCCCC;
        }
    }

    &__attribute-type {
        border-radius: .625rem;
        text-align: center;
        padding: 0px .625rem 2px .625rem;
        font-weight: normal;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -.9375rem;
        font-size: .8125rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        background-color: getColor(primary, lighter);
        color: getColor(primary);
        border: 1px solid getColor(primary);

        &--selected-grid-element {
            background-color: getColor(auxRed, darker);
            color: getColor(contrast, white);
            border: 1px solid getColor(primary);
        }

        &--parent-element {
            background-color: #EEEEEE;
            color: getColor(primary);
            border: 1px solid #CCCCCC;
        }
    }

    &__name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &__children-count {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        text-align: center;
        // padding: 0px .625rem 2px .625rem;
        font-weight: normal;
        position: absolute;
        right: 0;
        top: -.9375rem;
        font-size: .8125rem;
        background-color: getColor(primary, lighter);
        color: getColor(primary);
        border: 1px solid getColor(primary);

        &--selected-grid-element {
            background-color: getColor(auxRed, darker);
            color: getColor(contrast, white);
            border: 1px solid getColor(primary);
        }

        &--parent-element {
            background-color: #EEEEEE;
            color: getColor(primary);
            border: 1px solid #CCCCCC;
        }
    }
}

.foreignObject{
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
}
