.dashHome {
    display: flex!important;
    flex-direction: column!important;
    width: 100%;

    .titleMainSubH3 {
        margin-top: 20px;
        font-weight: 300;
        font-size: 16px;
    }

    .blackFocus {
        color: #888;
        font-weight: bold;
    }
}

.featureListWrapper {
    display: flex;
    column-gap: 40px;
    width: 100%;
    padding: 0 30px 30px 30px;
}
