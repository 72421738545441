@import '../../../../../../styles';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.checkboxWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.actionButton {
    appearance: none !important;

    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;

    background-color: transparent !important;
    border: none !important;
    color: getColor('primary');

    padding: 0;

    &:disabled {
        opacity: 0.4;
    }
    &.colorBlack {
        color: getColor('secondary');
        &:disabled {
            opacity: 0.6;
        }
    }
}
