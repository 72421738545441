@import '/src/styles';

.labeled-row {
    position: relative;
    padding: 1.5rem 0.75rem 0.75rem 0.75rem;
    border: 1px solid getColor(secondary, light);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 2rem;

    &__label {
        position: absolute;
        top: 0;
        left: 1.5rem;
        transform: translateY(-50%);
        user-select: none;

        &::before {
            content: '';
            width: calc(100% + 1.5rem);
            height: 2px;
            background-color: white;
            position: absolute;
            top: 50%;
            left: -0.75rem;
            z-index: -1;
            transform: translateY(-50%);
        }
    }
}
