.backModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(66, 66, 66, 0.35);
    z-index: 3;
    justify-content: center;
    align-items: center;

    .modal {
        position: relative;
        width: 500px;
        min-height: 20px;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 35px;
        color: #666666;
    }

    .cont {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 250px);
        float: left;
        overflow-y: auto;
    }

    .title {
        position: relative;
        top: -20px;
        width: 100%;
        min-height: 20px;
        float: left;
        box-sizing: border-box;
        padding: 10px 0 10px 0;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 18px;
        color: #f36f0e;
        border-bottom: 1px solid #f36f0e;
        font-size: 24px;
        font-weight: bold;
    }

    .closeBtn {
        position: absolute;
        top: 25px;
        right: 40px;
        cursor: pointer;
        font-size: 16px;
        color: #f36f0e;
        font-weight: 700;
        transform: scaleY(70%);
        z-index: 1;
    }
}
