@import '../../styles';

.tag {
    width: auto;
    appearance: none;
    padding: 0 .875rem;
    border-radius: 100px;
    font-size: .875rem;
    font-weight: 400;

    &.primary {
        border: 1px solid getColor('primary');
        background-color: getColor('primary');
        color: white;
    }

    &.secondary {
        border: 1px solid getColor('secondary');
        background-color: getColor('secondary');
        color: white;
    }

    &.deselect {
        border: 1px solid #BD3C3C;
        background-color: #BD3C3C;
        color: white;
    }

    &.info {
        border: 1px solid getColor('link');
        background-color: getColor('link');
        color: white;
    }
}
