@import '../../../../../styles';

.danger {
    color: #bd3c3c;
}

.usageList {
    list-style: none;
    padding: 0 0 1rem;

    li {
        padding: .25rem .75rem;
        margin: .25rem 0;
        border: .5px solid rgba(getColor('auxRed', 'light'), .4);
        border-radius: 6px;
        background-color: rgba(getColor('auxRed', 'light'), .4);
        color: getColor('auxRed', 'dark');
    }
}
