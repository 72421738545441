/**
 * All the colors can have only 5 tones, but not all the colors have 5 tones
*/
$palette: (
    primary: (
        dark: #ba550b,
        base: #f8710e,
        light: #ffa664,
        lighter: #fbf4f4,
    ),
    secondary: (
        darker: #212529,
        dark: #4f5963,
        base: #717785,
        light: #c4c4c4,
        lighter: #fafafa,
    ),
    auxGreen: (
        base: #3dc63d
    ),
    auxYellow: (
        base: #ca9400,
    ),
    auxRed: (
        darker: #BD3C3C,
        dark: #c05151,
        base: #ff6464,
        light: #ffa8a8,
        lighter: #ffe5e5
    ),
    disabled: (
        base: #cacaca,
        light: whitesmoke
    ),
    link: (
        dark: #178cbb,
        base: #1aade7,
        light: #6fcbef,
        lighter: #eefaff
    ),
    contrast: (
        white: white,
        black: black
    )
);

$grid-columns: 12;
$grid-gutter-width: 48px;
$grid-column-width: 48px;

$border-radius-size: 5px;

$grid-breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);
