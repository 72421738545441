.container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.editingValue {
    gap: 24px;
}

.errorModal {
    width: 650px;
}
