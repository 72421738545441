@import './ToolTip.module.scss';

.portalCont {
    position: absolute;
    z-index: 3;
}

.largeTooltip {
    width: 250px;
}
