@import '../../styles';

.badge {
    font-size: .8rem;
    line-height: 1rem;
    font-weight: 500;
    color: getColor('primary');
    border: 2px solid getColor('primary');
    background-color: rgba(getColor('primary'), .05);
    border-radius: 100px;
    padding: 0 .7rem;
}
