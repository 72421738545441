@import '../../../../styles';

.selectedLink {
    background: #fcfcfc!important;
    border-top: 1px solid getColor('primary')!important;
    border-bottom: 1px solid getColor('primary')!important;
}

.linkList {
    cursor: pointer;
    position: relative;
    font-weight: 300;
    padding: 12px 10px;
    box-sizing: border-box;
    padding-left: 30px;
    border-top: 1px solid #f9f8f7;
    border-bottom: 1px solid rgb(233, 229, 229);
    text-decoration: none!important;

    span {
      padding-left: 20px;
      font-weight: 400;
      color: getColor('secondary', 'dark');
    }

    i {
      position: relative;
      top: -1px;
      left: -14px;
      color: getColor('secondary', 'dark');
    }

    &:hover {
        background: #fcfcfc;
    }
}
