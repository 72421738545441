@import '../../../styles/';

.productContainer {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity linear 250ms;
    z-index: 1;

    background-color: getColor('primary');
    padding: 24px;
    border: 2px solid getColor('primary', 'dark');
    border-radius: 5px;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);

    &.active {
        visibility: visible;
        opacity: 1;
    }

    &.hidden {
        visibility: hidden;
        opacity: 0;
    }
}

.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.titleContent {
    position: relative;
    color: #ffffff;
    width: fit-content;
    margin-bottom: 1rem;

    &.center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.5rem;
        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &::after {
        position: absolute;
        content: '';
        background-color: #ffffff;
        width: 35.5%;
        height: 2px;
        bottom: -4px;
        left: 0;
    }
}

.descriptionContent {
    font-size: 1.05rem;
    margin-bottom: 1rem;
    color: #ffffff;
    &.center {
        margin-bottom: 1.5rem;
        text-align: center;
    }
}

.actionButtonsWrapper {
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
    opacity: 0;

    &.center {
        justify-content: center;
    }

    &.active {
        opacity: 1;
    }

    .previousButton {
        color: getColor('secondary', 'lighter');
        padding: 4px;
        &:hover {
            opacity: 0.7;
        }
    }

    .nextButton {
        color: getColor('link', 'lighter');
        padding: 4px 16px;
        background-color: #dd6212;
        &:hover {
            opacity: 0.7;
        }
    }
}

.skipButton {
    opacity: 0;
    color: rgba(getColor('link', 'lighter'), 0.7);
    padding: 4px 16px;
    font-size: 14px;

    &.active {
        opacity: 1;
        &:hover {
            color: rgba(getColor('link', 'lighter'), 1);
        }
    }
}
