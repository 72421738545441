@import '../../styles';

.labelBaseStyle {
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    color: getColor('secondary', 'darker');
    &.disabled {
        color: getColor('disabled');
    }
    &.hasHint {
        display: flex;
        align-items: center;
    }
    &.hasError {
        color: getColor('auxRed');
    }
}

.requiredIndicator {
    color: getColor('auxRed');
    vertical-align: text-top;
    margin-right: 2px;
}

.hintIcon {
    cursor: pointer;
    display: flex;
    margin-left: 8px;
    > i {
        align-self: center;
        color: getColor('secondary');
    }
}
