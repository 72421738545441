@import '/src/styles';

.unsaved-changes-modal {
    width: 100vw !important;

    @media screen and (min-width: break(sm)) {
        width: 80vw !important;
    }

    @media screen and (min-width: break(md)) {
        width: 60vw !important;
    }

    @media screen and (min-width: break(lg)) {
        width: 35vw!important;
    }

    &__paragraph {
        padding-bottom: 1rem;
    }

    &__footer {
        display: flex;
        justify-content: center;
        gap: 1.5rem;
    }
}
