@import '../../styles';

.tabLinks {
    cursor: pointer;
    text-align: center;
    color: getColor('primary');
    background-color: #ffffff;
    box-sizing: border-box;
    border: 1px solid #e4e4e4;
    border-radius: 5px 5px 0 0;
    border-bottom: unset;
    min-width: 100px;
    min-height: 50px;
    padding: 10px 20px;
    transition: min-height 200ms linear;
}

.tabSelected {
    position: relative;
    top: 1px;
    border: 1px solid getColor('secondary', 'light');
    border-bottom: unset;
    border-radius: 5px 5px 0 0;
    background: #fff;
    color: getColor('primary') !important;
    font-weight: 600;
    min-height: 60px;
    padding: 15px 20px 10px;
}

.disabled {
    cursor: default;
    background-color: getColor('disabled', 'light');
    color: getColor('disabled');
    border: 0.5px solid getColor('disabled');
    border-bottom: unset;
    border-radius: 5px 5px 0 0;
}
