@import '../../../../styles';

.grid {
    min-height: '0';
    width: '100%';

    td {
        white-space: nowrap;
        padding: 10px 15px !important;
        font-size: 13px;
    }

    button[title='Clear'] {
        display: none;
    }

    thead th {
        font-weight: 700;
        font-size: 16px;
        color: #f36f0e;
        padding: 15px;
    }

    thead th[aria-label='Filter'] {
        padding: 8px;
        color: #888;
    }
}

.warningMsg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
        font-size: 100px;
        margin-bottom: 20px;
        color: #f36f0e;
    }
}

.noList {
    position: relative;
    height: 200px;
}

.alignCenter {
    text-align: center !important;
}

.redCont {
    padding: 2px 5px;
    text-transform: uppercase;
    border: 1px solid getColor('auxRed', 'light');
    border-radius: 5px;
    font-size: 9px;
    text-align: center;
    color: getColor('auxRed');
    font-weight: 500;
}

.blueCont {
    padding: 2px 5px;
    text-transform: uppercase;
    border: 1px solid #89c1c9;
    border-radius: 5px;
    font-size: 9px;
    text-align: center;
    color: #89c1c9;
    font-weight: 500;
}

.greenCont {
    padding: 2px 5px;
    text-transform: uppercase;
    border: 1px solid getColor('auxGreen');
    border-radius: 5px;
    font-size: 9px;
    text-align: center;
    color: getColor('auxGreen');
    font-weight: 500;
}

.orangeCont {
    padding: 2px 5px;
    text-transform: uppercase;
    border: 1px solid getColor('auxYellow');
    border-radius: 5px;
    font-size: 9px;
    text-align: center;
    color: getColor('auxYellow');
    font-weight: 500;
}

#historyIntegrationSetup th[title='Status'],
#historyIntegrationSetup th[title='Is Force Sync'],
#historyIntegrationSetup th[title='Integration Method'] {
    text-align: center !important;
}
