.buttonBaseStyle {
    width: auto;
    padding: 4px 30px;
    border-radius: 48px;
    cursor: default;
}

.errorModal {
    width: 650px;
}
