@import '../../../../../../styles';

.container {
    display: flex;
    justify-content: space-between;
}

.inputGroup {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
}

.colorInput {
    appearance: none;
    cursor: pointer;
    border: 1px solid rgba(getColor('secondary'), 0.7);
    border-radius: 6px;
    background-color: transparent;
    width: 100%;
    max-width: 150px;
    transition: max-width 250ms ease-in-out;

    &:disabled {
        cursor: default;
        border: none;
        background-color: transparent;
        max-width: 64px;
    }

    &::-webkit-color-swatch {
        border-radius: 3px;
        border: 1px solid getColor('secondary');
    }
}

.colorInputLabel {
    margin: 0px;
}
