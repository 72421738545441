@import '../../../styles';

.container {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.inputBaseStyle {
    flex: 1;
    appearance: none;
    display: inline-block;
    cursor: pointer;
    border: 1px solid rgba(getColor('secondary'), 0.7);
    border-radius: 6px;
    background-color: transparent;
    transition: max-width 250ms ease-in-out;
    height: 37px;

    &:disabled {
        cursor: default;
        border: none;
        background-color: transparent;
        max-width: 64px;
    }

    &::-webkit-color-swatch {
        border-radius: 3px;
        border: 1px solid getColor('secondary');
    }
}

.colorInputLabel {
    position: absolute;
    top: 6px;
    left: 12px;
    font-size: 1rem;
}

.error {}
