.btnCont {
    bottom: 0;
    width: 100%;
    min-height: 20px;
    display: flex;
    justify-content: space-between;
}

.question {
    width: 100%;
    min-height: 20px;
    float: left;
    padding-bottom: 0;
    box-sizing: border-box;
    font-size: 21px;
    text-align: center;
    color: #ba550b;
    margin-bottom: 30px;

    span {
        font-weight: 700;
    }
}

.saveBtn {
    align-self: flex-start;
}

.buttonHolder {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding-right: 30px;
    margin-bottom: 30px;
}

.warningText {
    width: 100%;
    padding: 45px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
