.modal {
    width: 700px !important;
    & > div:last-child {
        overflow-y: hidden !important;
        overflow-x: hidden !important;
    }
}

.actionButtonsWrapper {
    display: flex;
    justify-content: center;
    column-gap: 24px;
}
