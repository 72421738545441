.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editingValue {
    gap: 24px;
}

.errorModal {
    width: 650px;
}

.sortOrderButton {
    &:disabled {
        border: none!important;
        background-color: transparent!important;
    }
}
