.componentCont {
    position: relative;
    width: 70%;
    height: 33px;
    float: left;
    z-index: 1;

    input {
        position: relative;
        width: 100%;
        height: 34px;
        background-color: rgba(242, 242, 242, 50%);
        padding: 10px;
        border-radius: 5px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid getColor('secondary', 'light') !important;
        float: left;
        border: none;
        z-index: 2;
    }

    .listCont {
        position: absolute;
        top: 35px;
        left: 2px;
        width: calc(100% - 4px);
        min-height: 20px;
        max-height: 165px;
        overflow-y: auto;
        background: #fff;
        border: 1px solid getColor('secondary', 'light');
        border-radius: 0 0 5px 5px;
        border-top: none;
        z-index: 1;

        .item {
            cursor: pointer;
            width: 100%;
            min-height: 20px;
            float: left;
            padding: 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #f5f5f5;
        }

        .item:hover {
            background: #eb8007;
            color: #fff;
        }

        .nomatch {
            width: 100%;
            min-height: 20px;
            float: left;
            padding: 10px;
            box-sizing: border-box;
            text-align: center;
            background: #ce2727;
            color: #fff;
        }
    }
}
