@import '../../../styles';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 150px;
    padding: 1rem;

    border: 1px dashed getColor('primary');
    border-radius: 5px;

    font-weight: 700;
    color: getColor('primary');
    background-color: getColor('primary', 'lighter');

    cursor: pointer;

    &.hasDrag {
        border: 2px dashed getColor('primary');

        & > * {
            user-select: none;
            pointer-events: none;
        }
    }
}

.fileListContainer {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        display: flex;
        align-items: center;
        i {
            margin-right: 0.5rem;
        }
    }
}
