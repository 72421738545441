@import '../../../../styles';

.errorModal {
    background-color: getColor('auxRed', 'dark') !important;
    color: #fff !important;
    p,
    span,
    div {
        color: #fff !important;
        border-color: #fff !important;
    }

    button {
        background: #fff;
        color: #cc6f6f;
        border: 1px solid #cc6f6f;
    }

    button:hover {
        background: #d8a5a5;
    }
}

.childParent {
    margin: 0;
    padding: 0;
    font-weight: bold;
}

.buttonCont {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.header {
    display: flex;
    flex-direction: row;
}

.buttonCont {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.btnCont {
    width: calc(100% - 300px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    button {
        width: 290px;
        flex-shrink: 1;
    }
}
