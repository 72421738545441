@import '../../../../styles';

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.actionButton {
    appearance: none;
    padding: 4px;
    background-color: transparent;
    border: none;
    color: getColor('secondary', 'dark');
}
