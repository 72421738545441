.integrations {
    width: 100%;
    min-height: 20px;
}

.statusIntModalConfig {
    p {
        color: #ba550b;
    }
}

.k-i-plus::before,
.k-plus::before {
    background-color: aliceblue;
    border-radius: 100px;
    color: #f8710e;
    font-size: 10px;
    font-weight: bold;
    padding: 2px;
}

.wrapper-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.relativeCont {
    position: relative;
}

.presentText {
    display: flex;
    column-gap: 40px;
    width: 100%;
    padding: 0 30px 30px 30px;
    box-sizing: border-box;
    .iconDash {
        font-size: 50px;
        margin-right: 20px;
    }
}

.limitedWidth {
    max-width: 700px;
}

.btn-dropdown {
    width: 231px;
}

.wrapperIntegrationTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapperContent {
    width: 70%;
}

.saveButton {
    height: 50px;
    width: 100px;
}

.buttonHolder {
    width: calc(100% - 60px);
    height: 50px;
    margin: 0 30px 30px 30px;
    box-sizing: border-box;
}
