@import '../../styles';

.container {
    position: fixed;
    visibility: hidden;
    overflow: hidden;

    height: 0;
    z-index: -1;

    &.active {
        visibility: visible;
        z-index: 99999;

        height: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
}

.elementWrapper {
    position: absolute;
    width: 75px;
    height: 75px;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.65), inset 0 0 2px 2px rgba(0, 0, 0, 0.65);

    border: 3px solid getColor('primary');
    border-radius: 5px;

    z-index: 0;
    transition: opacity linear 250ms, top cubic-bezier(0.41, -0.01, 0.79, 1) 250ms,
        left cubic-bezier(0.41, -0.01, 0.79, 1) 250ms;

    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, -50%);

    &.active {
        visibility: visible;
        opacity: 1;
    }
}

.wellcomeElementBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 0;
    visibility: visible;
}
