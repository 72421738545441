@import '../../../../../../styles';

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.col {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    align-items: center;
    z-index: 1;
}

.propertyLabel {
    font-size: 1rem;
    font-weight: bold;
    color: #717785;
}

.propertyValue {
    font-size: 3rem;
    letter-spacing: -3px;
    font-weight: 500;
    color: getColor('primary');
}

.icon {
    font-size: 150px!important  ;
}

