@import '/src/styles/';

.modal {
    p {
        cursor: default;
    }

    &__footer {
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        gap: 1rem;
    }
}
