@import '../../styles';

.container {
    padding: 0.5rem 1rem;
    border: 1px solid getColor('auxRed');
    border-radius: $border-radius-size;
    background-color: getColor('auxRed', 'lighter');

    color: getColor('auxRed');
    font-weight: 500;
}

// .alertContainer {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;

//     background-color: getColor('link', 'lighter');
//     padding: 8px 16px;
//     margin: 0px 0px 16px 0px;
//     border-radius: 5px;
//     border: 1px solid getColor('link');

//     font-size: 1rem;

//     column-gap: 24px;

//     .alertContent {
//         color: getColor('link');
//     }

//     .closeButton {
//         appearance: none;
//         width: auto;
//         font-size: .875rem;
//         padding: 4px 8px;
//         background-color: getColor('link', 'light');
//         border-radius: 5px;

//         color: white;
//         font-weight: 400;
//         border: none;

//         &:hover {
//             background-color: rgba(getColor('link', 'light'), .7);
//         }
//     }
// }
