@import '../../styles';

.default,
.cancelClose,
.errorPartial {
    transition: all 0.2s;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}

.default {
    color: #f8710e;
    background-color: #fbf4f4;
    border: 1px solid #f98733;
}

.default:hover {
    background-color: #fadfdf;
}

.cancelClose {
    border: 1px solid #ffffff;
    background: #bd3c3c;
    border: 1px solid #ba550b;
}

.cancelClose:hover {
    background: #972828;
}

.errorPartial {
    background: #fff;
    color: #cc6f6f;
    border: 1px solid #cc6f6f;
}

.errorPartial:hover {
    background: rgb(255, 240, 240);
}

.primaryButton {
    background: getColor('primary');
    color: #fff;
    border: 1px solid getColor('primary');
}

.primaryButton:hover {
    background: getColor('primary', 'dark');
}

.linkButton {
    padding: 4px 8px !important;
    font-weight: 500 !important;
    border: none;
    color: rgba(getColor('link'), 0.7);
    background-color: transparent;
    width: auto;
    &:hover {
        color: getColor('link');
    }
}

.disabled {
    background: rgb(245, 245, 245) !important;
    color: #cacaca !important;
    border: 1px solid #cacaca !important;
}

.disabled:hover {
    background: rgb(233, 233, 233) !important;
}

.btnSize-default {
    font-size: 1rem;
    font-weight: 700;
    padding: 10px 30px;
}

.btnSize-small {
    width: auto;
    font-size: .875rem;
    font-weight: 600;
    padding: 6px 15px;
}

.btnSize-large {
    font-size: 1.125rem;
    font-weight: 700;
    padding: 12px 36px;
}
